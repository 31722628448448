<template>
  <div class='pass'>
    <v-container>
      <v-row class='mt-10 flex-column align-center'>
        <div>
          <v-col cols='12' sm='12' md='12'>
              <h2 class='text-center'>Modificar Contraseña</h2>
          </v-col>
        </div>
      </v-row>
      <v-row>
        <v-col
          cols='12'
          offset-md='2'
          md='8'
          offset-sm='2'
          sm='8'
          offset-lg='4'
          lg='4'
        >
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
            v-model="password1"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="show1 ? 'text' : 'password'"
            label="Contraseña"
            name="password1"
            required
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
          <v-text-field
            v-model="password2"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="confirmPasswordRules"
            :type="show2 ? 'text' : 'password'"
            label="Confirmar contraseña"
            counter
            required
            name="password2"
            @click:append="show2 = !show2"
          ></v-text-field>
            <div class='text-center'>
              <v-btn :disabled="!valid" color='success' @click='cambiar'> Modificar </v-btn>
            </div>
            </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' offset-md='3' md='6' offset-sm='2' sm='8'>
          <v-alert
            :value='alertOk'
            border="bottom"
            color="green"
            dense
            elevation="5"
            prominent
            type="success"
          >
          <v-row align='center'>
              <v-col class='grow'>{{ this.mensaje }}</v-col>
             </v-row>
          </v-alert>
          <v-alert
            :value='alert'
            color='#F29398'
            dark
            border='top'
            icon='mdi-cancel'
            transition='scale-transition'
          >
            <v-row align='center'>
              <v-col class='grow'>{{ this.mensaje }}</v-col>
              <v-col class='shrink'>
                <v-btn color='#EC6D75' @click='cerrarBoton'>X</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Pass',
  data: () => ({
    valid: true,
    show1: false,
    show2: false,
    email: '',
    control: false,
    password1: '',
    password2: '',
    mensaje: '',
    alert: false,
    alertOk: false,
    token: localStorage.getItem('token'),
    passwordRules: [
      (value) => !!value || 'Ingresar una clave',
      (value) => (value && value.length >= 4) || 'Mínimo 4 carácteres',
    ],
  }),
  computed: {
    confirmPasswordRules() {
      return [
        (v) => !!v || 'Ingresar una clave',
        (v) => (v && v.length >= 4) || 'Mínimo 4 carácteres',
        (v) => (v === this.password1) || 'Las claves son diferentes',
      ];
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    cambiar() {
      if (this.$refs.form.validate()) {
        axios
          .post(`${API_URL}api/usuarios/editarClave`, {
            password: this.password1,
            id: localStorage.getItem('user_id'),
            token: this.token,
          })
          .then((response) => {
            if (response.data.status === 'error') {
              this.mensaje = response.data.message;
              this.alert = true;
            } else {
              this.alert = false;
              this.mensaje = response.data.message;
              this.alertOk = true;
              window.setInterval(() => {
                this.alertOk = false;
              }, 2000);
            }
          })
          .catch(() => {
            this.mensaje = 'Hubo un problema al modificar la clave';
            this.alert = true;
          });
      } else {
        this.mensaje = 'Las claves no son iguales';
        this.alert = true;
      }
    },
    cerrarBoton() {
      this.alert = false;
    },
    redireccion() {
      const token = localStorage.getItem('token');
      if ((token !== 'undefined') && (token !== null) && (token !== '')) {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            // this.$router.push('/');
          });
      }
    },
  },
  created() {
    this.redireccion();
  },
};
</script>
